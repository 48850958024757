@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  transition: background 0.25s ease-in-out;
  background: var(--body-bg) !important;
  color: var(--color-1) !important;
}
body {
  --body-bg: #ffffff;
  --color-1: #585858;
  --color-2: #333333;
  --color-3: #00958c;
  --color-4: #e84e66;
  --color-5: #a4a4a4;
  --color-6: #2ab974;
  --color-7: #ffffff;  
  --color-8: #ededed;
  --color-9: #ed7963;
  --color-10: #9f6503;
  --color-11: #c29933;
  --color-12: #d74351;
  --color-13:#d7d7d7;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-11);;
}
.form-control {
  box-shadow: none !important;
  color: var(--color-2) !important;
  padding: 10px !important;
}
.form-control:focus {
  border-color: var(--color-11) !important;
}
.form-check-input:checked {
  background-color: var(--color-11) !important;
  border-color: var(--color-11) !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  border-color: var(--color-11) !important;
}
nav.navbar {
  background: #ffffff !important;
  box-shadow: 0 2px 10px -2px var(--color-5);
  padding: 10px 0 !important;
}
nav.navbar  .navbar-brand:focus-visible{
 outline: 0 !important;
}
nav.navbar .offcanvas{
  max-width: calc(100% - 60px);
}
#sidebar {
  overflow: hidden;
  z-index: 3;
}
#sidebar .list-group {
  background-color: var(--color-7);
  min-height: 100vh;
  border-radius: 0;
}
#sidebar i {
  margin-right: 6px;
}
#sidebar .list-group-item {
  border-radius: 0;
  background-color: var(--color-7);
  color: var(--color-1);  
  white-space: nowrap;
  border: none;
  font-size: 14px;
  padding: 12px 15px;
}
#sidebar .list-group-item.active {
  color: var(--color-10);
}
/* highlight active menu */
#sidebar .list-group-item:not(.collapsed) {
  background-color: var(--color-7);
}
/* closed state */
#sidebar .list-group .list-group-item[aria-expanded="false"]::after {
  content: "\F282";
  font-family: bootstrap-icons;
  display: inline;
  position: absolute;
  right: 15px;
}
/* open state */
#sidebar .list-group .list-group-item[aria-expanded="true"] {
  background-color: var(--color-11);
  color: var(--color-7);
}
#sidebar .list-group .list-group-item[aria-expanded="true"]::after {
  content: "\F286";
  font-family: bootstrap-icons;
  display: inline;
  position: absolute;
  right: 15px;
}
/* level 1*/
#sidebar .list-group .collapse .list-group-item,
#sidebar .list-group .collapsing .list-group-item {
  padding-left: 42px;
}
/* level 2*/
#sidebar .list-group .collapse > .collapse .list-group-item,
#sidebar .list-group .collapse > .collapsing .list-group-item {
  padding-left: 30px;
}
/* level 3*/
#sidebar .list-group .collapse > .collapse > .collapse .list-group-item {
  padding-left: 40px;
}
.accordion_button {
  cursor: pointer;
  position: relative;
}
.accordion_button::after {
  content: "\F282";
  font-family: bootstrap-icons !important;
  margin-left: 8px;
}
.accordion_button:not(.collapsed)::after {
  content: "\F286";
  font-family: bootstrap-icons !important;
}
.accordion-collapse {
  width: 100%;
}
.arbitrage_filter_form .form-check.form-switch {
  width: 15%;
  font-size: 14px;
}
.arbitrage_filter_form .form-control {
  width: 30%;
  font-size: 14px;
}



/* .collapse.show {
  visibility: visible;
}
.collapsing {
  visibility: visible;
  height: 0;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: 100%;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
} */


.verifyFlex {
  display: flex;
  align-items: center;
}

.verifyFlex .progress {
  width: 90%;
  margin-right: 20px;
  background: var(--color-13);
  height: 8.16px;
  overflow: visible;
}

.verifyFlex .progress-bar {
  background: var(--color-5) !important;
  position: relative;
  overflow: visible;
  border-radius: 10px;
}

.verifyFlex .progress-bar::before {
  content: "";
  background: var(--color-1);
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  top: -4px;
  right: 0;
}

.verifyFlex h4 {
  color: var(--color-6);
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 0;
}


.themeglo .form-control,
.themeglo .form-select {
  padding: 12px;
  border: 0;
  outline: 0;
}

.themeglo .form-control:focus,
.themeglo .form-select:focus {
  box-shadow: none;
  border: 0;
}

.themeglo .btn-close {
  display: none !important;
}

.themeglo input::-webkit-outer-spin-button,
.themeglo input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.modal-content {
  border-radius: 25px !important;
  border: 0 !important;
}

.act_activityglo  .nav-tabs .nav-link.active,
.act_activityglo  .nav-tabs .nav-link:hover{
  background: var(--color-10);
  color: var(--color-7) !important;
}
.act_activityglo table{
  color: var(--color-1);
  border-color: var(--color-13);
  white-space: nowrap;
}
.act_activityglo  .nav-tabs .nav-link {
  margin: 0 0px 0 20px;
  padding: 12px 36px;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border: 0 !important;
  color: var(--color-1) !important;
  font-size: 20px !important;
  transition: 0.5s;
}
.act_activityglo  .nav-tabs {
  border-bottom: 0 !important;
}



.custom_select.form-select {
  border: 1px solid var(--color-5)  !important;
  color: var(--color-1);
  background: var(--color-7);
  box-shadow: none !important;
}
.custom_inp.form-control {
  border: 1px solid var(--color-5)  !important;
  color: var(--color-1) !important;
  background: var(--color-7);
  box-shadow: 0 0 15px -5px var(--color-5) !important;
}
textarea {
  resize: none !important;
}
.ivu-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.ivu-form-item-label{
  width: 150px;
  margin-right: 15px;
}
.table-body.asrt-table-body {
  margin-top: 0 !important;
}
.historyCryptoTable thead{
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.historyCryptoTable thead th{
  padding: 8px 0;
}
.historyCryptoTable tr td{
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}
.table-foot{
  margin-top: 12px !important;
}
.status_filter_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.status_filter_head .mb-0 {
  margin-bottom: 0 !important;
}
.status_filter_head select+select {
  margin-left: 10px;
}
select.status_filter{
  width: unset;
}
.status_filter_head label{
  margin-bottom: 0;
}
.ivu-row {
  display: flex;
  align-items: center;
}
.uploadfile {
  position: relative; 
}
.uploadfile > input[type='file'] {
  display: none
}
.uploadfile > label {  
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
}
#navbarNav .nav-link{
  color: #585858 !important;
}
#navbarNav .nav-link:hover{
  color: var(--color-10) !important;
}
#navbarNav .nav-item.active .nav-link{
  color: var(--color-10) !important;
}
@media (max-width: 991px){
  #sidebar .list-group .collapse .list-group-item, #sidebar .list-group .collapsing .list-group-item {
    padding-left: 28px !important;
  }

.verifyFlex h4 {
    font-size: 28px;
}
}

@media (max-width: 575px){
  .act_activityglo .nav-tabs{
    flex-direction: column;
  }
  .act_activityglo .nav-tabs .nav-link{
    font-size: 16px !important;
    transition: 0.5s;
    width: 205px;
    text-align: center;
    border-radius: 15px;
  }
  .arbitrage_filter_form .form-control {
    margin-bottom: 10px;
}
.arbitrage_filter_form .form-check.form-switch{
  width: unset;
}
.arbitrage_filter_form .form-control{
  width: 100%;
}
.status_filter_head{
  flex-direction: column;
  align-items: baseline;
}
.status_filter_head h3{
  margin-bottom: 15px !important;
}
}
.login_wrapper {
  display: flex;  
  justify-content: space-between;
  align-items: center;  
  min-height: 100vh;
}
.login_card {
  border: 1px solid var(--color-8);
  border-radius: 8px;
  box-shadow: 0 0 15px -5px var(--color-5);
  padding: 30px;
}
.login_card h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  color: var(--color-2);
  margin: 10px 0 15px;
}
.login_card h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-1);
}
.login_card a {
  color: var(--color-10);
  text-decoration: none;
}
.login_card a:hover {
  text-decoration: underline;
}
.auth_btn {
  background: var(--color-10);
  border: 2px solid var(--color-10);
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  font-size: 18px;
  font-weight: 600;
}
.auth_btn:hover {
  background: transparent;
  border: 2px solid var(--color-10);
  color: var(--color-10);
}
.sidebarwrapper {
  width: 200px;
  box-shadow: 2px 0px 10px -2px var(--color-5);
}
.main_header {
  box-shadow: 0 2px 10px -2px var(--color-5);
}
.main_header .sidebarwrapper{
 display: none;
}
.main_header .sidebarwrapper{
  width: unset;
  box-shadow: none;
}
.main_header .sidebarwrapper a{
  padding-left: 5px !important;
}

.page_wrapper {
  display: flex;
}
.maincontent_wrapper {
  width: calc(100vw - 200px);
  padding: 30px 50px;
}
.refersh_time {
  color: var(--color-9);
  font-size: 14px;
  margin-bottom: 30px;
}
.arbitrage_panel {
  margin-bottom: 30px;
}
.buy_text {
  color: var(--color-3);
}
.sell_text {
  color: var(--color-4);
}
.buy_sell_text {
  font-weight: 700;
  margin-right: 5px;
  font-size: 12px;
}
.coin_text_logo {
  display: flex !important;
  align-items: center;
  color: var(--color-2);
  font-weight: 600;
  font-size: 12px;
}
.coin_text_logo img {
  margin-left: 5px;
}
.arbitrage_div_row {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-8);
  padding: 10px;
}
.arbitrage_div_column span {
  display: block;
}
.coin_count {
  color: var(--color-2);
  font-weight: 600;
  font-size: 14px;
}
.coin_price {
  font-size: 14px;
  color: var(--color-2);
  font-weight: 500;
}
.price_per_unit {
  color: var(--color-5);
  font-weight: 500;
  font-size: 12px;
}
.arbitrage_div_column:first-child {
  width: 21%;
}
.arbitrage_div_column:nth-child(2) {
  width: 51%;
}
.arbitrage_div_column:last-child {
  width: 28%;
}
.arbitrage_div_column span a {
  color: var(--color-2);
  font-weight: 600;
  font-size: 14px;
  display: block;
  text-decoration: none;
}
.buy_sell_info_header {
  color: #464646;
  font-size: 12px;
  font-weight: 700;
}
.buy_sell_info_value {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
}
.accordion_body {
  padding: 10px;
}
.accordion_body div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arbitrage_div_accordian_row {
  padding: 0;
  border-top: none;
  border-bottom: none;
}
.arbitrage_div_accordian_row:last-child {
  border-bottom: 1px solid var(--color-8);
}
.arbitrage_div_greenrow {
  background: var(--color-6);
}
.arbitrage_text {
  font-weight: 700;
  font-size: 12px;
  color: var(--color-7);
}
.transfer_time_text {
  font-size: 12px;
  color: var(--color-7);
}
.livetrack_btn {
  display: flex;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 0 10px -2px var(--color-1);
  background-color: var(--color-8);
  border: 1px solid var(--color-8);
  font-weight: 700;
  color: #464646;
  cursor: pointer;
  font-size: 11px;
  padding: 3px 8px;
  height: 30px;
}
.livetrack_btn img {
  margin-right: 5px;
}
.arbitrage_filter form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.filter_btn {
  background: var(--color-10);
  border: 2px solid var(--color-10);
  color: var(--color-7);
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 600;
}





.titleBox .h6tag{
  font-size: 24px;
  margin-bottom: 15px;
}


.smallCard {
  background: var(--color-8);
  border-radius: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
}

.smallCard .small_flex {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-13);
  padding: 20px 20px 0px;
}

.smallCard .smallpad {
  padding: 20px 20px 25px;
}
.smallCard .smallpad .subTitle{
  font-size: 24px;
}
.smCardTitle {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.smCardTitle img {
  margin-right: 15px;
}

.smCardTitle .subTitle {
  font-size: 22px;
  font-weight: 500;
}
.split {
  font-size: 12px;
  margin: 0 14px;
}
.last_login_record .split {
  color: var(--color-2);
}

.last_login_record {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

.last_login_record a {
  color: var(--color-2);
  text-decoration: none;
}
span.last_login_time {
  color: var(--color-1);
}

.account_info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.account_info img {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  margin-right: 15px;
}

.nickname_edit {
  margin-bottom: 5px;
}

.user_nickname {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-2);
  word-break: break-word;
}

.edit_icon {
  margin-left: 8px;
  color: var(--color-2);
  cursor: pointer;
}

.verification_level span {
  font-weight: 500;
  font-size: 18px;
  color: var(--color-2);
}

.account_wrapper_div {
  padding: 24px 15px;
  box-sizing: border-box;
  background: var(--color-5);
  background: var(--color-7);
  box-shadow: 0 0 15px -5px var(--color-5);
  border-radius: 20px;
  margin-bottom: 10px;
  width: 100%;
}

.account_security_info .account_security_info_first {
  display: flex;
  align-items: flex-start;
  /* min-width: 280px;
  width: 280px; */
  color: var(--color-2);
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.account_security_info .account_security_info_first label {
  white-space: normal;
  font-size: 18px;
}

.account_security_info .account_security_info_middle {
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-top: 5px;
}

.account_security_info_middle .not_verify {
  color: var(--color-2);
}

.account_security_info_middle .not_verify i {
  color: var(--color-12) ;
}

.account_security_info_middle span {
  display: flex;
  align-items: center;
  color: var(--color-2);
}

.account_security_info_middle span i {
  color: var(--color-2);
  margin-right: 5px;
}

.account_security_info_middle small.red {
  color: var(--color-12) !important;
  margin-left: 0 !important;
}

.account_security_info_middle span i.bi-exclamation-circle-fill {
  margin-right: 5px;
  color: var(--color-12);
}

.account_security_info .account_security_info_middle small {
  color: var(--color-2);
  margin-left: 20px;
}

.account_security_info .account_security_info_last {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
}

.account_wrapper_div .btnSecondary {
  font-size: 16px;
  border: 1px solid transparent;
  padding: 10px;
  width: 198px;
  display: block;
  text-align: center;
  border-radius: 10px;
  background: var(--color-10);
  color: var(--color-7);
  margin-top: 8px;
}


.themeModal {
  background: var(--color-13);
  border-radius: 10px;
}



.choose_profile_wrap {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin-bottom: 30px;
}

.choose_profile_wrap>div {
  text-align: center;
  position: relative;
  cursor: pointer;
}

.selected_avatar {
  position: absolute;
  bottom: 0;
  right: 10px;
}

.modal_footer_btn {
  display: flex;
  margin: 35px 0 0px;
}

.modal_footer_btn button {
  width: 100%;
  font-size: 16px;
  border: 1px solid transparent;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  background: var(--color-10);
  color: var(--color-7);
}

.modal_footer_btn button+button {
  margin-left: 15px !important;
}

.themeModal .head {
  border-color: var(--color-5);
}

.themeModal .intext {
  background: var(--color-5);
  color: var(--color-7);
  border: 0;
}

.themeModal .intext:focus-visible {
  outline: 0;
}

.themeModal label {
  color: var(--color-1);
}

.themeModal .tfa_wrap {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.themeModal .tfa_wrap input {
  text-align: center;
  padding: 10px !important;
}

.themeModal .link_class {
  color: var(--color-1);
  text-decoration: none;
}

.themeModal .close_icon {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: var(--color-8);
  opacity: 1;
  z-index: 9;
  box-shadow: none !important;
  width: 32px;
  height: 32px;
  padding: 5px;
  border-radius: 50%;
  border: 0;
}

.themeModal .close_icon:hover {
    background-color: var(--color-8);
  opacity: .75;
}

.themeModal .close_icon i {
  color: var(--color-2);
}

.themeModal .form_note {
  color: var(--color-1);
}

.themeModal .hide {
  background: var(--color-5);
  border: 0;
}

.themeModal .hide i {
  color: var(--color-7);
}

.boxStyle{
  background:var(--color-7);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 15px -5px var(--color-5);
}



.kyc_upload_wrap {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

.kyc_upload_div {
  width: 100%;
  -webkit-box-flex: 1;
  flex: 1 1 40%;
  margin-top: 10px;
}

.kyc_upload_item {
  width: 100%;
  height: 220px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  border: 1px solid var(--color-5) !important;
  background: var(--color-7);
  box-shadow: 0 0 15px -5px var(--color-5);
  padding: 10px;
  margin: 0;
  text-align: center;
  position: relative;
  -webkit-transition: border-color .2s ease-out;
  transition: border-color .2s ease-out;
  -webkit-box-flex: 0;
  flex: none;
}

.kyc_upload_drag {
  padding: 5px 5px;
  width: 100%;
  height: 196px;
  max-height: 200px;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  position: relative;
  vertical-align: middle;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.kyc_upload_item input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: none;
}

.kyc_upload_item h4 {
  margin: 5px 0;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: 100%;
  color: #b7b7b7;
  font-size: 18px;
}

.kyc_upload_item h4 i {
  display: block;
  font-size: 36px;
}

.kyc_upload_wrap .kyc_upload_div:nth-child(1) {
  margin-right: 8px;
}

.kyc_upload_wrap .kyc_upload_div:nth-child(2) {
  margin-left: 8px;
}


.support_ticket_card_panel {
  height: 628px;
  overflow: auto;
  padding-right: 15px;
}

/* Support Ticket */
.support_ticket_card {
  background-color: var(--color-7);
  box-shadow: 0 0 15px -5px var(--color-5);
  border-radius: 10px;
  margin-bottom: 20px;
  transition: 0.2s all;
  border: 1px solid transparent;
  cursor: default;
}

.support_ticket_card:hover,
.support_ticket_card.active {
  border: 1px solid var(--color-6);
}

.stbadge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.stbadge .badge {
  font-size: 14px;
  font-weight: 400;
  padding: 7px 20px;
}

.ticket_content_body {
  box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  padding: 40px 20px 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.ticket_content_body img {
  width: 50px;
  height: auto;
  border-radius: 50px;
  -webkit-box-flex: 0;
  flex: 0 0 50px;
  margin-right: 15px;
}

.ticket_content h3 {
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--color-1);
}

.ticket_content p {
  color: var(--color-1);
  font-size: 14px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 72%);
  white-space: nowrap; */
  margin-bottom: 0;
}

.ticket_content_foot {
  background-color: var(--color-5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 0 0 10px 10px;
}

.ticket_content_foot label {
  font-size: 14px;
  color: var(--color-1);
  font-weight: 500;
}

.ticket_content_foot>div label:first-child {
  color: var(--color-2);
  margin-right: 5px;
}

.support_ticket_msgbox {
  background-color: var(--color-7);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 10px 20px;
}


.stmsg_header h3 {
  font-size: 18px;
  margin-bottom: 15px !important;
}

.stmsg_chatbox {
  border-bottom: 1px solid #13142a;
  padding-bottom: 20px;
}

.stmsg_chatbox:last-child {
  border-bottom: 0;
}

.stmsg_chatbox_info {
  padding: 10px 0px;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.stmsg_chatbox_info img {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.stmsg_info {
  margin-left: 6px;
}

.stmsg_info_label {
  color: #adb5bd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.stmsg_info_value {
  display: inline-block;
  vertical-align: middle;
}


.reply_chat_box {
  padding: 20px 25px;
  margin-left: 15px;
  font-size: 14px;
  background-color: var(--color-8);
  border-radius: 6px;
}

.reply_chat_box p {
  margin-bottom: 8px;
}

.ticket_attachment {
  text-align: right;
  margin-top: 10px;
}

.attachment_tag {
  display: inline-block;
  padding: 5px 10px;
  padding-right: 20px;
  margin-bottom: 5px;
  background-color: var(--color-5);
  color: var(--color-1);
  border-radius: 6px;
  font-size: .72rem;
}

.stmsg_chatbox+.stmsg_chatbox {
  margin-top: 20px;
}

.admin_chatbox .reply_chat_box {
  background-color: var(--color-13);
}

.chat_box_panel {
  height: 450px;
  overflow: auto;
  padding-right: 10px;
}

.chat_footer_panel {
  padding: 15px 15px 0 0;
  margin-left: 15px;
}

.chat_footer_panel button+button {
  margin-left: 10px;
}

.chat_footer_btngrps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


button.replybtn {
  background: var(--color-10);
  color: var(--color-7);
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
}
button.replybtn:hover {
  background: var(--color-10);
}
th.sortable{
  color: #585858;
}
.btn-primary{
  background: var(--color-10) !important;
  border-color: 2px solid var(--color-10) !important;
  color: var(--color-7) !important;
}
button.closebtn,
.closebtn {
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 16px;
  background: var(--color-5);
  color: var(--color-2);
  border-radius: 10px;
}
.tradingbot{
  padding: 20px;
  box-shadow: 0 0 15px -5px var(--color-5);
  background: var(--color-7);
  border-radius: 10px;
  height: 100%;
}
.tradingbot .flxbox{
  display: flex;
}
.tradingbot form .row{
  align-items: center;
}
.tradingbot form label{
  margin-bottom: 0;
}
.tradingbot form select{
  box-shadow: none !important;
}   
.tradingbot form select:focus{
  border-color: var(--color-11) !important;
}
@media (max-width:1300px) {
  .chat_footer_btngrps {
    flex-direction: column;
    align-items: flex-start;
  }
  button.closebtn {
    margin-top: 10px !important;
  }
  }
@media (max-width:991px) {
  .sidebarwrapper{
    display: none;
  }
  .main_header .sidebarwrapper{
    display: block;
   }
   .main_header  ul{
    padding-right: 0 !important;
   }
   .maincontent_wrapper{
    width: calc(100vw - 0px);
    padding: 30px 15px;
   }
  .verifyFlex h4 {
    font-size: 28px;
  }
}

@media (max-width:767px) {
  .account_info {
    margin-bottom: 15px;
  }
  .smallCard .small_flex {
    flex-direction: column;
  }
  .tradingbot label{
    margin-bottom: 5px !important;
  }
}

@media (max-width:575px) {
.choose_profile_wrap {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.ticket_content_foot,.ticket_content_body{
  flex-direction: column;
align-items: baseline;
}
.ticket_content h3{
  margin-top: 10px;
}
.stmsg_chatbox_info{
  flex-direction: column;
text-align: center;
}
.stmsg_chatbox_info img{
  margin-right: 0;
}

.arbitrage_filter form{
  flex-direction: column;
    align-items: baseline;
}
}